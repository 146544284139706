<template>  
  <section class="hero is-light is-fullheight faqs">
    <div class="hero-head">
      <Navbar />
    </div>
    <div class="hero-body is-align-items-flex-start">
      <div class="container">

        <div class="columns is-centered">
          <div class="column is-8">
            <h1 class="subtitle is-size-3 mb-6">
              FAQS
            </h1>

            <InfoBox
              title="What is Swagible's main offering?"
              info="Swagible is your go-to platform for corporate merch. We offer a catalog of customizable products, making it easy for you to create personalized swag packages for your recipients.​​ We also prioritize the security of your recipients' personal information and handle all the shipping, ensuring a hassle-free experience from start to finish."
            />

            <InfoBox
              title="How can I customize the swag with my company's branding?"
              info="It's simple! You can easily customize the swag with your company's logo when you browse our catalog and create your campaign."
            />

            <InfoBox
              title="How does the payment process work?"
              info="We've made it hassle-free. Pre-authorize your payment once, and you won't need to keep track of individual orders. You'll only be charged for the swag your recipients order."
            />

            <InfoBox
              title="How will my recipients receive their personalized packages?"
              info="We'll send your recipients an email with a personalized link. Through this link, they can easily pick the items they want, choose sizes and colors, and safely share their shipping address. Your information and your recipients' details are kept private and secure by Swagible."
            />

            <InfoBox
              title="Are there any minimum order quantities or restrictions?"
              info="Swagible is flexible. We accommodate both small and large orders, and there are no minimum order quantities or restrictions. You can create personalized packages tailored to your needs."
            />

            <InfoBox
              title="What types of products are available in your catalog?"
              info="Our catalog offers a wide range of products, from apparel to promotional items and beyond. You can explore and choose the perfect items to create a unique and memorable swag package. Feel free to explore our catalog by clicking here."
            />

            <InfoBox
              title="Is there a setup fee or subscription required to use Swagible?"
              info="Swagible offers a user-friendly, pay-as-you-go model. There are no setup fees or subscription requirements. You only pay for the swag your recipients claim, making it a cost-effective solution for your corporate merch needs."
            />

            <InfoBox
              title="Are there any shipping fees for the packages?"
              info="Shipping fees are typically calculated based on the size and weight of the package, as well as the destination. We provide transparent pricing, so you'll know the shipping costs upfront."
            />

            <InfoBox
              title="How long does it take to receive an order?"
              info="Orders are usually shipped and fulfilled within 14 days, depending on the location of your recipients."
            />

            <InfoBox
              title="Are there any rush delivery options?"
              info="We personalize your orders and provide local delivery for a quick experience.

Stay tuned! Rush delivery options for even quicker service are coming soon."
            />

            <InfoBox
              title="Is there a return policy?"
              info="If the recipient hasn't redeemed their link, you can cancel the order free of charge.

If the recipient has already redeemed their link, but we haven't shipped the order, contact support@swagible.com. A 30% charge may apply to cancel the order for that recipient."
            />

            <InfoBox
              title="What measures are in place to ensure data privacy and security?"
              info="Swagible prioritizes data privacy and security. We never share recipients' personal information and employ industry-standard encryption to protect your data."
            />

            <InfoBox
              title="Can I get support if I have questions or need assistance?"
              info="Absolutely! Our customer support team is here to assist you every step of the way. Feel free to reach out to support@swagible.com if you have any questions or need help with anything related to your campaign or orders."
            />

          </div>
        </div>

      </div>
    </div>
    <div class="hero-foot">
      <Footer />
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import Navbar from '@/components/Navbar1.vue'
import InfoBox from '@/components/InfoBox.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: { Navbar, InfoBox, Footer },
  setup() {
    const email = ref('')
    const name = ref('')
    const message = ref('')

    const handleSubmit = async () => {
      alert('handleSubmit')
    }

    return {
      email,
      name,
      message,

      handleSubmit,
    }
  }
}
</script>

<style>
.faqs .field {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--grey);
}

.faqs .label {
  color: #000 !important;
  font-size: 1em;
}

.faqs p {
  color: #000;
  font-weight: 400;
}
</style>